
import Splide from '@splidejs/splide';
import { Global } from '../objects/global';

document.querySelector('#tryAgain').addEventListener('click', () => {
    document.querySelector('#popupPage').classList.remove('active')
})
document.querySelector('#closeInfo').addEventListener('click', () => {

    document.querySelector('#popupPage').classList.remove('active')

})
document.querySelector('#colseQuiz').addEventListener('click', () => {

    document.querySelector('#popupPage').classList.remove('active')
    $('.parentBox ').removeClass('active')

})


const splide = new Splide('#splide2', {
    //   type: 'loop',
    interval: 2500,
    //   pauseOnHover: false,
      perPage: 1,
    // padding: '20%',
    //   pauseOnFocus: false,

}).mount();

// Event listener for slide change
splide.on('move', (newIndex) => {
    const activeElement = splide.Components.Elements.slides[newIndex];
    // console.log('Active Slide Index:', newIndex);

    // Get the class name of the active slide element
    const classes = Array.from(activeElement.classList);
    // console.log('Active Slide Class List:', classes); // Log all classes
    // console.log(Global.productArray,activeElement.classList);


    // Find the class that is not the default classes
    const specificClass = classes.find(cls => cls !== 'splide__slide' && cls !== 'is-visible' && cls !== 'is-next' && cls !== 'is-active');

    if (specificClass) {
        console.log('Active Slide Class Name:', specificClass); // This will log "bottle_spearmint" or any other specific class
        // document.querySelector('.itemName').innerHTML = Global.products[specificClass]
        Global.slectedQuiz = specificClass
        const baseClassName = specificClass.split('_').pop(); // Split by underscore and take the first part
        $('.bar').removeClass('active')
        $(`.${specificClass}_bar`).addClass('active')
        // document.querySelector('#product').src = `/UI/${baseClassName}.png`
        // 


    }
});
$('.options').click(function(){
    let classes = $(this).attr('class').split(' ');
    
    // Check if there is a second class and get it
    let secondClass = classes[1] || null;
    $(`.${secondClass}`).removeClass('active')
    $(this).addClass('active')
    Global[secondClass]=$(this).html()
    if(secondClass=='answer1'||secondClass=='answer2'){
        splide.go('>');    
    }
    if(Global.answer1!=''&&Global.answer2!=''&&Global.answer3!=''){
        $('#colseQuiz').trigger('click')
    }
  
    
})