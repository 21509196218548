let Global = {
    BLOOM_SCENE: 1,
    itemsToRayCastRight: [],
    itemsToRayCastLeft: [],
    itemsToRayCastDown: [],
    itemsToRayCastUp: [],
    itemsToCollect: [],
    energyTotal: 0,
    playCount: 0,
    fileType1: '',
    fileType2: '',
    fileType3: '',
    scoreTotal: 0,
    posX1: 0,
    posY1: 0,
    posX2: 0,
    posY2: 0,
    posX3: 0,
    posY3: 0,
    startPos: null,
    scale1: 1,
    scale2: 1,
    scale3: 1,
    userWidth1: 0,
    userWidth2: 0,
    userWidth3: 0,
    userHeight1: 0,
    userHeight2: 0,
    userHeight3: 0,
    moveExecuted: false,
    lastDirection: null,
    signedDirection: null,
    lastSignedDirection: null,
    lastMoveDirection: null,
    volumeBool: false,
    firstName: '',
    videoStream:'',
    canUpload: true,
    base64data: '',
    gameTime: 0,
    gameTry: 0,
    product_no: 0,
    rotateTO: null,
    canRotate: true,
    vBool: true,
    gameKey: null,
    loginStatus: false,
    gender: "male",
    firstname: '',
    lastname: '',
    imageNo: 1,
    streamStarted: false,
    ang1: 0,
    ang2: 0,
    currentPage: 'introPage',
    selectedState:'',
    ang3: 0,
    lang: 'ENG',
    selectedType: 'email',
    message: '',
    uploadType: 'image',
    url: 'https://newsfeedsmartapp.com/oreoElHabba/assets/poster.png',
    movePossibleDirns: {
        'x': [1],
        'z': [0]
    },
    recipientType: 'Parents',
    recipient: '',
    countryCode: '',
    confirmBool:true,
    selectedMessage: 'message1',
    loaderBool:false,
    events: null,
    volumeBool: false,
    lastCheckPt: null,
    productArray: {
        wild_cherry_stick: {
            product_no: 0,
            status: 3,
        },
        air_rush_stick: {
            product_no: 0,
            status: 3
        },
        spearmint_stick: {
            product_no: 0,
            status: 3
        },
        wild_cherry_bottle: {
            product_no: 0,
            status: 3
        },
        air_rush_bottle: {
            product_no: 0,
            status: 3
        },
        spearmint_bottle: {
            product_no: 0,
            status: 3
        },
        tropical_bottle: {
            product_no: 0,
            status: 3
        },
        strawberry_and_lime_center_filled_infinity: {
            product_no: 0,
            status: 3
        },
        spearmint_center_filled_infinity: {
            product_no: 0,
            status: 3
        },
        tropical_infinity: {
            product_no: 0,
            status: 3
        },
        raspberry_and_lemon_double_layer_envelope: {
            product_no: 0,
            status: 3
        },
        watermelon_and_peach_double_layer_envelope: {
            product_no: 0,
            status: 3
        },
        berry_envelope: {
            product_no: 0,
            status: 3
        },
        mint_envelope: {
            product_no: 0,
            status: 3
        },
    },
    products: {
        wild_cherry_stick: 'WILD CHERRY STICK',
        air_rush_stick: 'AIR RUSH STICK',
        spearmint_stick: 'SPEARMINT STICK',
        wild_cherry_bottle: 'WILD CHERRY BOTTLE',
        air_rush_bottle: 'AIR RUSH BOTTLE',
        spearmint_bottle: 'SPEARMINT BOTTLE',
        tropical_bottle: 'X TROPICAL BOTTLE',
        strawberry_and_lime_center_filled_infinity: 'INFINITY STRAWBERRY AND LIME CENTER FILLED',
        spearmint_center_filled_infinity: 'INFINITY SPEARMINT CENTER FILLED',
        tropical_infinity: 'INFINITY TROPICAL',
        raspberry_and_lemon_double_layer_envelope: 'INFINITY RASPBERRY AND LEMON DOUBLE LAYER',
        watermelon_and_peach_double_layer_envelope: 'INFINITY WATERMELON AND PEACH DOUBLE LAYER',
        berry_envelope: 'INFINITY BERRY',
        mint_envelope: 'INFINITY MINT',
    },
    slectedProduct: 'Template01',
    slectedQuiz: 'question1',
    answer1:'',
    answer2:'',
    answer3:'',
    selectedState:'',
    // URL_CREATE: "https://newsfeedsmartapp.com/WinWithPushpa/api/click/webservice.php",
    URL_VUPDATE: "https://newsfeedsmartapp.com/WinWithPushpa/api/valueUpdator.php",
    // // URL_GENERATE: "https://newsfeedsmartapp.com/OreoMaze/api/generate.php",
    URL_DATA: "https://newsfeedsmartapp.com/WinWithPushpa/api/webservice.php",
    URL_upload: "https://newsfeedsmartapp.com/WinWithPushpa/api/upload2.php",

    //  clickUpdator(clm) {
    //     // Global.serverObj.send(Global.URL_VUPDATE, null, null, {
    //     //   uniqID: Global.U_ID,
    //     //   saveType: clm,
    //     //   status: Global.loginStatus,
    //     // });
    //     $.ajax({
	// 	url: 'https://newsfeedsmartapp.com/WinWithPushpa/api/valueUpdator.php', 
	// 	method: 'POST', 
	// 	dataType: 'json', 
	// 	data: {
	// 		saveType: clm,
	// 		uniqID:Global.U_ID
	// 	},
	// 	success: function(responseData) { 
			
	// 	},
	// 	error: function(jqXHR, textStatus, errorThrown) { 

	// 	}
	//   });
    // },
    buttonMusic(){
        document.querySelector('#buttonMusic').currentTime = 0;
        document.querySelector('#buttonMusic').play().catch(error => {
            // console.error("Playback failed:");
        });;
    },

    sliderAnimation(page1, page2, sign) {
        Global.currentPage = page2
        document.querySelector('.' + page1 + 'Container').style.height = 0;
        setTimeout(() => {
            document.getElementById(page1).style.display = "none";
            document.getElementById(page2).style.display = "flex";
            setTimeout(() => {
                document.querySelector('.' + page2 + 'Container').style.height = sign + '%';
            }, 100);

        }, 600);
      
    },
    buttonAnimation(page1, page2, sign, button) {
        // document.getElementById(button).style.scale = 0.8
        // // document.querySelector('.'+button).style.display = 'block'
        // var counter2 = 0
        // var looper2 = setInterval(() => {
        //     counter2 += 1;
        //     if (counter2 === 21) {
        //         // counter2 = 1;

        //         clearInterval(looper2)
        //         // document.querySelector('.'+button).style.display = 'none'
        //     }
        //     // $("#"+button).find('.buttonEffect').hide()
        //     $("#"+button).find('.buttonEffect').attr('class',`buttonEffect button-effect-${counter2}`) 
        // }, 50);
        // let t1=setTimeout(() => {
        //     clearTimeout(t1)
        //     document.getElementById(button).style.scale = 1

        // }, 500);
        // let t2= setTimeout(() => {
        //     clearTimeout(t2)

        //     Global.sliderAnimation(page1, page2, sign)
        // }, 1000);
        Global.sliderAnimation(page1, page2, sign)

    },
    clickUpdater(clm) {
        $.ajax({
            url: 'https://newsfeedsmartapp.com/WinWithPushpa/api/valueUpdator.php',
            method: 'POST',
            dataType: 'json',
            data: {
                saveType: 'clickUpdater',
                columnName: clm,
                click_id: Global.U_ID
            },
            success: function (responseData) {

            },
            error: function (jqXHR, textStatus, errorThrown) {
                //  localStorage.clear()
                //  window.location.href = '/';
            }
        });
    },
    updateClicks(clm){
        Global.serverObj.sendData(Global.URL_VUPDATE, null, null, {
            uniqID: Global.U_ID,
            saveType: clm,
          });
    }


}

export {
    Global
}