import { Global } from "../objects/global"


document.querySelector('#back1').addEventListener('click', () => {
    Global.updateClicks('back_click')
    // document.querySelector(".footerImg").classList.remove("active")
    document.querySelector(".footerImg").classList.add("active3")
    
    window.adobeDataLayer.push({
        "event": "linkClick",
        "eventInfo": {
            "eventName": "linkClick"
        },
        "linkInfo": {
            "linkName": "chakragold:back",
            "linkPosition": "bottom",
            "linkType": "Button"
        },
        "page": {
            "pageName": "chakraGold:are you ready",
            "language": "en_IN",
            "campaignName": "Pushpa2",
        }
    });


    window.adobeDataLayer.push({
        "event": "landed",
        "eventInfo": {
            "eventName": "landed"
        },
        "page": {
            "pageName": "chakragold:landingScreen",
            "language": "en_IN",
            "campaignName": "Pushpa2"
        }
    });

    Global.sliderAnimation('startPage', 'introPage', 75)
    
});
document.querySelector('#pc').addEventListener('click', () => {
    Global.clickUpdater('pc_click')
    
    window.open('https://www.tataconsumer.com/privacy-notice', '_blank')
})
document.querySelector('#start').addEventListener('click', () => {
    Global.updateClicks('ready_click')
    Global.sliderAnimation('startPage', 'pushpaPage', 70)
    window.adobeDataLayer.push({
        "event": "linkClick",
        "eventInfo": {
            "eventName": "linkClick"
        },
        "linkInfo": {
            "linkName": "chakragold:next",
            "linkPosition": "bottom",
            "linkType": "Button"
        },
        "page": {
            "pageName": "chakraGold:are you ready",
            "language": "en_IN",
            "campaignName": "Pushpa2",
        }
    });
    window.adobeDataLayer.push({
        "event": "landed",
        "eventInfo": {
            "eventName": "landed"
        },
        "page": {
            "pageName": "chakraGold:instruction",
            "language": "en_IN",
            "campaignName": "Pushpa2"
        }
    });
    Global.sliderAnimation('startPage', 'pushpaPage', 70)
})
document.querySelector('#back2').addEventListener('click', () => {
    Global.updateClicks('back_click')
    window.adobeDataLayer.push({
        "event": "linkClick",
        "eventInfo": {
            "eventName": "linkClick"
        },
        "linkInfo": {
            "linkName": "chakragold:back",
            "linkPosition": "bottom",
            "linkType": "Button"
        },
        "page": {
            "pageName": "chakraGold:instruction",
            "language": "en_IN",
            "campaignName": "Pushpa2"
        }
    });
    
    window.adobeDataLayer.push({
        "event": "landed",
        "eventInfo": {
            "eventName": "landed"
        },
        "page": {
            "pageName": "chakragold:are you ready",
            "language": "en_IN",
            "campaignName": "Pushpa2",
        }
    });
    Global.sliderAnimation('pushpaPage', 'startPage', 70)
    
});
document.querySelector('#next').addEventListener('click', () => {
    Global.updateClicks('next_click')
    window.adobeDataLayer.push({
        "event": "linkClick",
        "eventInfo": {
            "eventName": "linkClick"
        },
        "linkInfo": {
            "linkName": "chakragold:next",
            "linkPosition": "bottom",
            "linkType": "Button"
        },
        "page": {
            "pageName": "chakraGold:instruction",
            "language": "en_IN",
            "campaignName": "Pushpa2"
        }
    });

    window.adobeDataLayer.push({
        "event": "landed",
        "eventInfo": {
            "eventName": "landed"
        },
        "page": {
            "pageName": "chakraGold:ChoosePosterTemplate",
            "pageType": "Home",
            "language": "en_IN",
            "campaignName": "Pushpa2"
        }
    });

    Global.sliderAnimation('pushpaPage', 'productPage', 70)
})
document.querySelector('.home').addEventListener('click', () => {
    Global.updateClicks('home_click')
    window.adobeDataLayer.push({
        "event": "linkClick",
        "eventInfo": {
            "eventName": "linkClick"
        },
        "linkInfo": {
            "linkName": "chakragold:Home",
            "linkPosition": "bottom",
            "linkType": "Button"
        },
        "page": {
            "pageName": "chakraGold:youPoster",
            "language": "en_IN",
            "campaignName": "Pushpa2"
        }
    });

setTimeout(() => {
    window.location.reload();

}, 50);})

