import { Global } from "../objects/global"

document.querySelector('#pose').addEventListener('click', () => {
    Global.updateClicks('pose_click')
    window.adobeDataLayer.push({
        "event": "linkClick",
        "eventInfo": {
            "eventName": "linkClick"
        },
        "linkInfo": {
            "linkName": "chakragold:#posewithpushpa",
            "linkPosition": "bottom",
            "linkType": "Button"
        },
        "page": {
            "pageName": "chakragold:landingScreen",
            "language": "en_IN",
            "campaignName": "Pushpa2",
        }
    });


    window.adobeDataLayer.push({
        "event": "landed",
        "eventInfo": {
            "eventName": "landed"
        },
        "page": {
            "pageName": "chakragold:are you ready",
            "language": "en_IN",
            "campaignName": "Pushpa2",
        }
    });





    document.querySelector(".footerImg").classList.add("active")
    document.querySelector(".footerImg").classList.remove("active3")
    Global.sliderAnimation('introPage', 'startPage', 70)
})
document.querySelector('#quiz').addEventListener('click', () => {
    window.adobeDataLayer.push({
        "event": "linkClick",
        "eventInfo": {
            "eventName": "linkClick"
        },
        "linkInfo": {
            "linkName": "chakragold:join now",
            "linkPosition": "bottom",
            "linkType": "Button"
        },
        "page": {
            "pageName": "chakragold:landingScreen",
            "language": "en_IN",
            "campaignName": "Pushpa2",
        }
    });
    Global.updateClicks('quiz_click')

    window.open('https://fanizm.com/live-quiz-contest/2zKfB5PiW?referral=D5D839&sgmty=bGl2ZV9xdWl6', '_blank')
})
